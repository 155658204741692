.shipping-panel {
  .gift-options__content .sub-section {
    display: none;
  }
}

.review-panel {
  .review-terms {
    margin-bottom: 15px;
  }
  #gift-message-h,
  .gift-message-text {
    display: none;
  }
}

.confirmation-panel__delivery-date {
  @include breakpoint($bp--medium-up) {
    border-left: none;
  }
}

.confirmation-panel__confirm-text {
  @include breakpoint($bp--medium-up) {
    padding: 0;
  }
}

.checkout-registration__email-address {
  span.email {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 21px 12px;
    width: 100%;
  }
}

.panel {
  .viewcart &.links-panel,
  .checkout &.links-panel,
  .viewcart &.need-help-panel {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
}

@include breakpoint($bp--medium-up) {
  .checkout__content .order-summary__content {
    .label {
      @include layout($grid--12, 1em) {
        @include grid-span(3, 3);
        clear: both;
      }
      &.total {
        @include layout($grid--12, 1em) {
          @include grid-span(8, 3);
          clear: both;
        }
      }
      &.gst-tax,
      &.pst-tax,
      &.hst-tax {
        @include layout($grid--12, 1em) {
          @include grid-span(2, 4);
          clear: both;
        }
      }
    }
    .value {
      @include layout($grid--12, 1em) {
        @include grid-span(2, 10);
      }
      &.gst-tax,
      &.pst-tax,
      &.hst-tax {
        @include layout($grid--12, 1em) {
          @include grid-span(2, 9);
        }
      }
    }
  }
}

.checkout {
  .order-summary__content {
    .gst-tax,
    .pst-tax,
    .hst-tax {
      font-size: 11px;
      line-height: inherit;
      @include breakpoint($bp--medium-up) {
        font-size: 16px;
        line-height: 8px;
      }
      &.label {
        padding-left: 20px;
      }
      &.value {
        padding-right: 20px;
      }
    }
  }
}

.order-totals {
  .gst-tax,
  .pst-tax,
  .hst-tax {
    font-size: 11px;
    line-height: inherit;
    th {
      padding-left: 20px;
    }
    td {
      padding-right: 20px;
    }
  }
}

@include breakpoint($bp--medium-down) {
  #colorbox.loyalty-appliedpopup {
    position: fixed !important;
    top: 35% !important;
    height: 200px !important;
    #cboxLoadedContent {
      height: auto !important;
      padding-top: 5em;
    }
  }
}

.continue-buttons {
  #bottom-viewcart-buttons & {
    @include breakpoint($bp--medium-up) {
      padding-#{$rdirection}: 10%;
      text-align: #{$rdirection};
    }
  }
}

.checkout {
  #offer-code-panel {
    .offer-code__input {
      input {
        background-color: $color--white;
      }
    }
  }
}

.checkout-optimization {
  .checkout {
    .cart-item {
      .cart-item__product-price--non-sale:before {
        @include transform(skewY(-12deg));
      }
    }
  }
  &.viewcart {
    .cart-item {
      &__error--limited-qty {
        @include swap_direction(margin, 4% 0 0 35%);
        @include breakpoint($bp--medium-up) {
          @include swap_direction(margin, 14% 0 0 40%);
        }
        @include breakpoint($bp--xxlarge-up) {
          @include swap_direction(margin, 12% 0 0 41%);
        }
        p {
          @include breakpoint($bp--medium-up) {
            max-width: 116px;
          }
        }
      }
    }
  }
}

.bundle-kit {
  .cart-item__desc-info,
  .cart-item__size-shade {
    min-height: auto;
  }
  .color {
    &.column {
      padding-bottom: 20px;
    }
  }
}

.checkout__index-content {
  .checkout__sidebar {
    .preorder_message {
      width: 100%;
      float: $ldirection;
    }
  }
}

.order-details-page {
  .order-products {
    .product-header {
      font-size: 15px;
      @include breakpoint($bp--xxlarge-up) {
        font-size: 18px;
      }
    }
  }
}

.checkout-confirmation-page {
  .panel--loyalty-join {
    &__content {
      .form-item {
        margin-top: 10px;
        @include breakpoint($bp--medium-up) {
          width: 500px;
        }
        &.email {
          padding-bottom: 10px;
        }
        .postal-code {
          &::before {
            white-space: break-spaces;
            vertical-align: middle;
            line-height: get-line-height(15px, 18px);
          }
        }
      }
    }
  }
}
