.site-footer--bottom {
  @include breakpoint($bp--largest-up) {
    @include fontsize-rem($typesize--14px);
    .menu > li {
      padding-right: 0.6em;
    }
  }
  @include breakpoint($bp--xxlarge-up) {
    .menu > li {
      padding-right: 1.8em;
    }
  }
}
