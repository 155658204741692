$ab-ipad-portrait: $width-large - 1 $width-xlarge - 1;

.appointment-nav {
  .submenu {
    &__header {
      h3 {
        @include breakpoint($ab-ipad-portrait) {
          font-size: 2rem;
        }
      }
    }
  }
}
