.location-quebec {
  .footer-liveperson-link,
  .livechat,
  #product-page-livechat-hitarea {
    display: none !important;
    visibility: hidden !important;
  }
}

.lp_desktop,
.lp_mobile {
  #lpChat {
    .lp_pages_area {
      #LP_DropDownQuestion_1,
      #LP_DropDownQuestion_2,
      #LP_DropDownQuestion_3,
      #LP_DropDownQuestion_4,
      #LP_DropDownQuestion_5,
      #LP_DropDownQuestion_6 {
        .lp_select_field {
          text-decoration: none;
          text-transform: capitalize;
          padding-right: 10%;
        }
        .lp_select_field option {
          text-transform: capitalize;
        }
        .lp_lpview_content:after {
          position: relative;
          bottom: 21px;
          font-family: 'icons';
          font-size: 24px;
          @include corner-triangle();
          content: '';
        }
      }
      ::-webkit-input-placeholder {
        text-transform: capitalize;
      }
    }
  }
}
