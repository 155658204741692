.product__shipping {
  margin-bottom: 15px;
}

.page-product {
  .product {
    &__edd {
      display: none;
    }
  }
}
