.grid--mpp__item {
  .product--teaser {
    &.prod_inv_status-2 {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock__msg {
            display: none;
          }
        }
      }
    }
    &.prod_inv_status-3 {
      .product__footer {
        .product__inventory-status {
          .coming-soon {
            display: block;
          }
        }
        .product__add-to-bag {
          &.coming-soon {
            display: none !important;
          }
        }
      }
    }
    &.product--preorder {
      .product {
        &_header_details {
          z-index: 2;
          position: relative;
        }
      }
    }
  }
}

.product--full {
  .product__bag-bar {
    &--sticky {
      .product__inventory-status {
        .temp-out-of-stock__msg {
          display: none;
        }
      }
    }
  }
}

.mobile-collection-slide {
  .product--teaser.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status {
        li {
          height: auto;
          padding: 0px;
          .temp-out-of-stock__msg {
            display: none;
          }
        }
      }
    }
  }
}

.grid--mpp__item {
  .prod_inv_status-2 {
    & > {
      .shade-picker__color-actions {
        a {
          display: block;
        }
        .js-inv-status-2 {
          display: none;
        }
      }
    }
  }
}

// Waitlist Overlay Styles

.waitlist-iframe-wrapper {
  width: 95%;
  height: 290px;
  @include swap_direction(margin, 17px 7px 5px 7px);
  @include breakpoint($bp--large-up) {
    height: 250px;
  }
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
    @include swap_direction(margin, 10px 0);
  }
  .field {
    .email_comp {
      .email_input {
        width: 66%;
        float: left;
        @include breakpoint($bp--large-up) {
          width: 75%;
        }
      }
      .align-r {
        width: 30%;
      }
      .align-r,
      .submit_input {
        float: $ldirection;
        margin-#{$ldirection}: 10px;
        .input-btn {
          height: 58px;
        }
      }
    }
    .terms_condition_cont {
      float: $ldirection;
      @include swap_direction(margin, 5px 5px 0 0);
      clear: both;
    }
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

#cboxClose {
  position: absolute;
  top: 5px;
  right: 15px;
}

.shipping-edit-content {
  .email-and-sms-promotions {
    &__info-text {
      font-size: 11px;
      line-height: 11px;
    }
    .section-sms-promotions {
      .promotions-container {
        .sms-mobile-terms {
          font-size: 9px;
          margin-top: 5px;
          line-height: 10px;
          b {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.profile-page__content {
  .sms-promotions {
    display: none;
  }
  .sms-promotions__fieldset {
    .field-container {
      @include breakpoint($bp--medium-up) {
        max-width: 100%;
        input[type='text'] {
          max-width: 660px;
        }
      }
    }
  }
  .profile-info {
    &__change_pwd_link {
      @include breakpoint($bp--medium-up) {
        text-align: $ldirection;
        margin-#{$rdirection}: 0;
        float: $rdirection;
        width: 50%;
      }
    }
  }
}

.pr-qa-display-question {
  .pr-qa-display-answer-link-container {
    display: none;
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      .product-header {
        .quantity {
          margin-#{$ldirection}: 47%;
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 44.44%;
          }
        }
        .rating {
          margin-#{$ldirection}: 58%;
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 61.11%;
          }
        }
      }
    }
  }
}

.contact-us-page {
  .contact-nav {
    @include breakpoint($bp--large-up) {
      margin: 0 auto;
      max-width: 800px;
    }
    &__videos {
      margin-bottom: 20px;
      @include breakpoint($bp--large-up) {
        margin-bottom: 0;
      }
    }
    li {
      @include breakpoint($bp--large-up) {
        width: 33.33% !important;
        margin: 0 !important;
        float: $ldirection !important;
        clear: none !important;
        padding-#{$rdirection}: 2em !important;
        min-height: 270px;
      }
      .details {
        @include breakpoint($bp--large-up) {
          border-bottom: 0;
          min-height: auto;
        }
      }
    }
  }
}

.product-foundation-vto__info-container {
  margin-bottom: -29px;
}

.account-nav {
  .account-nav__section {
    .sidebar-menu__item {
      &.extole a {
        text-transform: lowercase;
        padding-top: 12px;
        display: inline-block;
      }
    }
  }
}
.site-header-formatter__offers {
  .header-offers-banner-formatter {
    height: 60px;
    @include breakpoint($bp--large-up) {
      height: 32px;
    }
    .header-offers-banner-formatter__carousel {
      display: block;
      text-align: center;
      @include breakpoint($bp--large-up) {
        display: flex;
      }
    }
    .header-offers-banner-item {
      width: auto;
      height: auto;
      padding: 0 25px;
      @include breakpoint($bp--large-up) {
        height: 32px;
        padding: 0;
      }
      .header-offers-banner-item__offer-text {
        top: auto;
        transform: none;
        @include breakpoint($bp--large-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &__extole-text {
        div {
          display: inline-block;
          a {
            line-height: 1;
            font-family: $ano-bold_regular-font;
            font-size: 15px;
            font-weight: 400;
            color: $color--yellow;
            margin-#{$ldirection}: 6px;
            @include breakpoint($bp--large-up) {
              line-height: 2.1;
            }
          }
        }
      }
    }
  }
}

.viewcart {
  .continue-buttons,
  .sticky-checkout-button {
    .paypal-smart-button {
      @include breakpoint($bp--large-up) {
        vertical-align: middle;
      }
      &.paypal-loading {
        position: relative;
        background: url(/media/dev/global/icon_loading.gif) no-repeat center center
          $color--gray--white;
        height: 55px;
        border-radius: 4px;
        text-align: center;
        @include breakpoint($bp--large-up) {
          vertical-align: middle;
        }
      }
    }
  }
}
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-compound-selectors */
.site-header__wrapper {
  .site-header-formatter {
    .site-my-mac-v1 {
      .site-my-mac__contents-signed_in {
        .site-my-mac__contents-footer {
          .loyalty__level__details {
            .mac-select__color-tier1 {
              color: $color--bronze-generic;
            }
            .mac-select__color-tier2 {
              color: $color--silver-generic;
            }
            .mac-select__color-tier3 {
              color: $color--gold-generic;
            }
          }
        }
      }
    }
  }
}

.mac-select-loyalty-image {
  .mac-select-loyalty-image__upload {
    @include breakpoint($bp--medium-up) {
      max-width: none;
    }
  }
}

.mac-select-loyalty-join {
  .mac-select-loyalty-join__signin {
    .mac_select-signin {
      color: $color--white;
      text-decoration: underline;
    }
  }
}
