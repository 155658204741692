#cookie_popover_container {
  position: fixed;
  bottom: 0;
  border: white;
  background-color: $color--black;
  color: white;
  display: none;
  padding: 22px 30px 22px 22px;
  width: 100%;
  border-top: solid 1px $color--gray--lightest;
  z-index: 10000;
  @include breakpoint($bp--medium-up) {
    width: 295px;
    height: auto;
    right: 10px;
    bottom: 10px;
    border: solid 1px $color--gray--lightest;
    word-wrap: break-word;
  }
  a {
    color: white;
    text-transform: none;
  }
  .cookie_legal-close_box {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}
