/**
 * @file us_pc.scss
 *
 * \brief Pull all parts of the US-PC theme together into one file
 */

@import 'overrides/overrides';
//SCSS variable configuration to include LBO related CSS specific to this locale.
$product-full-overview-height: 390px;
$lbo-enabled: true;
$opc_update: true;
$mac-lover: true;
$cr20: true;
$password_strengthen: true;
$appt_booking: true;
$replenishment_reskin: true;
$fonts_update: true;
$loyalty-redesign: true !default;

@import '../../mac_base/scss/mac-base';
@import 'components/_site-header';
@import 'components/_email-signup';
@import 'components/_opc__page--checkout';
@import 'components/_cookie_legal';
@import 'components/_livechat';
@import 'sections/pc/_footer';
@import 'sections/pc/_promo_spp';
@import 'sections/_shared';
@import 'components/_social_login';

//One Trust Cookie
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';
// Appt Booking
@import 'sections/appt_booking';

// Track My Order.
@import 'standalone/elc-order-tracking';
