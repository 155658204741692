/* ==========================================================================
   Components Organisms - Header
   ========================================================================== */

.site-header {
  .site-header__tools {
    .site-email-signup {
      &__contents {
        &-form {
          p {
            margin: 0 0 10px 0;
          }
          .intro {
            width: 96%;
          }
          .error {
            display: none;
          }
          .form-item {
            margin: 0 0 10px 0;
          }
          .form-type-checkbox {
            margin-bottom: 0;
            display: none;
          }
          .picker-checkbox {
            display: block;
            margin: 0;
          }
          .picker-handle {
            float: left;
          }
          .picker-label {
            text-transform: none;
            width: 95%;
            p {
              margin: 0 0 10px 0;
            }
          }
        }
        &-submit {
          @include btn;
          @include btn--full;
        }
      }
    }
    .block-template-site-email-signup-ca-v1 {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: inline-block;
      }
    }
  }
}

.form--newsletter {
  &__subtitle {
    margin: 0 0 5px;
  }
  .error_messages {
    margin: 0 0 5px;
  }
  &__form-items {
    .form-item {
      margin: 0 0 7px;
    }
  }
}

// Hide "I received my MAC Select ID checkbox" when user loggedin.
.elc-user-state-logged-in {
  .js-grandfathered-mac-select-id {
    display: none;
  }
}

.js-grandfathered-mac-select-id-hidden {
  display: none;
}
